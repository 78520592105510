import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home/Hero";
import AboutScrye from "../sections/home/AboutScrye";
import Team from "../sections/home/Team";
import FooterMenu from "../components/Footer/FooterMenu";

const IndexPage = () => (
  <PageWrapper footerDark>
    <Hero/>
    <AboutScrye />
    <Team />
    <FooterMenu />
  </PageWrapper>
);
export default IndexPage;
