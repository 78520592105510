import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgU1 from "../../assets/image/png/scrye-team/team-1.png";
import imgU2 from "../../assets/image/png/scrye-team/team-2.png";
import imgU3 from "../../assets/image/png/scrye-team/team-3.png";
import imgU4 from "../../assets/image/png/scrye-team/team-4.png";
import imgU5 from "../../assets/image/png/scrye-team/team-5.png";
import imgU6 from "../../assets/image/png/scrye-team/team-6.png";


const TeamSection = styled(Section)`
  background: ${({ theme }) => theme.goatColors.primary };
  position: relative;
  z-index: 1000;
`;

const CardImage = styled.div`
  max-width: 160px;
  min-width: 160px;
  min-height: 160px;
  max-height: 160px;
  overflow: hidden;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 29px;
`;

const PageTitle = styled(Title)`
  color: ${({ theme }) => theme.goatColors.text };
  font-family: 'futura';
`;

const CardTitle = styled(Title)`
  color: ${({ theme }) => theme.goatColors.text };
`;

const CardText = styled(Text)`
  color: ${({ theme }) => theme.goatColors.text };
`;

const TeamCard = ({ userImg, title, children, ...rest }) => (
  <Box
    className="text-center"
    pt="15px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <CardImage>
      <img src={userImg} className="img-fluid" alt="" />
    </CardImage>

    <div className="text-center">
      <CardTitle variant="card" fontSize="24px" letterSpacing={-0.75} my={1}>
        {title}
      </CardTitle>

      <CardText fontSize={2} lineHeight={1.75}>
        {children}
      </CardText>
    </div>
  </Box>
);



const Team = () => (
  <>
    {/* <!-- Team section --> */}
    <TeamSection id="the-team" className="position-relative pt-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" className="text-center pb-3">
            <div className="">
              <PageTitle color="#fff">The Team</PageTitle>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgU1} title="Scott Salandy-Defour">
              CEO
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgU2} title="Bradley Clarke">
              Solidity Wizard
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgU3} title="Gabriel Ibarra">
              Engineering Warlock
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgU4} title="Brad McGlasson">
              Web Wrangler
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgU5} title="Jacob Madden">
              AI Whisperer
            </TeamCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
            <TeamCard userImg={imgU6} title="Matt Wright">
              Advisor + Community Expert
            </TeamCard>
          </Col>
        </Row>
      </Container>
    </TeamSection>
  </>
);

export default Team;
