import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { rgba } from "polished";
import styled from "styled-components";
import { Title, Section, Box, Text } from "../../components/Core";
import { device, theme } from "../../utils";

// import InfinityTriangles from '../../components/InfinityTriangles';
import imgContent from "../../assets/image/logos/scrye-logo.png";


const AboutScryeSection = styled(Section)`
    position: relative;
    z-index: 50;
`;

const AboutContainer = styled(Container)`
  position: relative;
  z-index: 50;
`;

const AboutPanel = styled.div`
  background: ${({ theme }) => theme.goatColors.panelBg };
  box-shadow: -8px -11px 0px #252526;
`;

const AboutImg = styled.img`
  @media screen and (max-width: 768px) { 
    max-width: 250px;
  }
`;


const AboutTitle = styled(Title)`
  font-family: 'futura', helvetica;
  text-shadow: 1px 3px 9px #000;
  position: relative;
  top: -40px;
  left: 30px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.goatColors.text };
  font-size: 6rem;
  background: radial-gradient(closest-corner at 30% 50%,rgb(0 0 0 / 31%) 30%,transparent 70%);
`;

const AboutBody = styled(Text)`
  background: ${({ theme }) => theme.goatColors.panelTextbg };
  padding: 5px 50px 40px;
  color: ${({ theme }) => theme.goatColors.panelText } !important;
  font-family: 'goat-roboto', 'futura', sans-serif;
  font-weight: 300;
`;


const OverlayCut = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 120vw;
    height: 60vh;
    z-index: 10;
    background: ${({ theme }) => theme.goatColors.primary };
    transform: rotate(150deg) translate(23%, 95%);
`;

const ExitCut = styled.div`
  position: absolute;
  z-index: 10;
  background: ${({ theme }) => theme.goatColors.primary };
  transform: rotate(-70deg);

  bottom: -42vh;
  right: -200vw;
  width: 220vw;
  height: 110vh;

  @media only screen and ( min-width: 460px) {
    bottom: -62vh;
    right: -110vw;
    width: 130vw;
    height: 120vh;
  }

  @media only screen and ( min-width: 1024px) {
    bottom: -22vh;
    right: -90vw;
    width: 130vw;
    height: 1000px;
  }

  @media only screen and ( min-width: 1460px) {
      bottom: -10vh;
    right: -60vw;
    width: 120vw;
    height: 100vh;
  }
`;

const AboutScrye = () => (
  <>
    {/* <!-- Content section --> */}
    <AboutScryeSection id="about-scrye" className="position-relative">

      <OverlayCut />

      <AboutContainer>
        <Row className="align-items-center">

          {/* LEFT PANEL - IMAGE*/}
          <Col lg="4" md="12" sm="12" className="mb-5 mb-lg-0 order-lg-1">
            <div
              data-aos="fade-left"
              data-aos-duration="750"
              data-aos-once="true"
            >
              {/* <AboutImg src={imgContent} alt="" className="img-fluid" /> */}
            </div>
          </Col>

          {/* RIGHT PANEL - CONTENT */}
          <Col lg="6" className="order-lg-2">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >

              <AboutPanel className="content-text">
                <div className="section-title">
                  <AboutTitle>
                    Who?
                  </AboutTitle>
                  <AboutBody>
                  Scrye is an Emerging Tech Venture Studio focused on the intersection of NFTs, AR, and AI. Connecting the metaverse (digital) to the universe (physical).
                  </AboutBody>
                </div>
              </AboutPanel>

            </div>
          </Col>
        </Row>
      </AboutContainer>

      <ExitCut />

    </AboutScryeSection>
  </>
);

export default AboutScrye;
