import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { device } from "../../utils";

import { Title, Section, Box, Text } from "../../components/Core";
import GoatButton from "../../components/GoatButton";
import AnimatedFace from "../../assets/image/logos/animated_logo_face_white.svg";
import FaceLogoSvg from "../../assets/image/svg/_scrye/youxscrye.svg";

const SectionStyled = styled(Section)`
  background-color: ${({ theme}) => theme.goatColors.primary  };
  z-index: 100;
`;

const ImgRight = styled(Box)`
  position: relative;
  right: 0;

  @media ${device.md} {
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    left: auto;
    margin-top: -280px;
  }

  svg path {
    animation: faceroll 30s linear infinite;
    
    @keyframes faceroll {
      0% { stroke: #00bcc9; }
      32% { stroke: #00bcc9; }
      34% { stroke: #7000db; }
      65% { stroke: #7000db; }
      67% { stroke: #f000de; }
      98% { stroke: #f000de; }
      100% { stroke: #00bcc9; }
    }
  }

`;


const HeroTitle = styled(Title)`
  color: ${({ theme}) => theme.goatColors.title  };
  font-family: 'goat-roboto';
  font-weight: 300;
`;

const HeroSubtitle = styled(Text)`
  font-family: 'goat-roboto','roboto', sans-serif;
  font-weight: 300;
`;


const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="5" className="order-md-2 image-wrap">
              <ImgRight>
                <AnimatedFace />
              </ImgRight>
            </Col>
            <Col md="7" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]}  pt={[4, null]}>

                  <HeroTitle variant="hero" color="light">
                    <FaceLogoSvg/>
                  </HeroTitle>

                  <HeroSubtitle mb={4} color="light" opacity={0.7}>
                    Connecting the universe with the metaverse.<br/>
                    <br/>
                    Scrye makes avatars interoperable between virtual worlds and the real world, enabling the connection of the metaverse and the universe.
                    <Text
                      variant="medium"
                      fontSize={"18px"}
                      color="#0ff0ff"
                      opacity={1}
                    >
                      You are 1 of 1
                    </Text>
                  </HeroSubtitle>


                  <div className="d-flex flex-column align-items-center pt-3">
                    {/* <GoatButton text="SCAN" /> */}
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
